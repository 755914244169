<template>
  <div class="me">
    <div
      :class="{
        'editor-toolbar': mode == 'editor',
        editing: isEditing
      }"
    >
      <slot name="toolbar"></slot>
    </div>
    <div class="panel-canvas" :style="panelStyle" @click.stop.prevent="onEdit">
      <div
        ref="edit"
        :class="isEditing ? 'editable v-cell' : 'v-cell'"
        v-html="content"
        :contenteditable="editableContent"
      />
    </div>
  </div>
</template>
<script>
import RichTextForm from "@/components/control-sidebar/property-editors/rich-text-form.vue";
import { isSyncEnabled } from "@/services/dashboard.js";
export default {
  name: "DashboardRichTextPanel",
  props: {
    display: {
      type: Object,
      required: true
    },
    panel: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      hasFocus: false
    };
  },
  computed: {
    content: {
      set(value) {
        this.trigger({
          action: "content:change",
          details: value
        });
      },
      get() {
        return this?.panel?.options?.content || "";
      }
    },
    panelStyle() {
      let style = JSON.parse(JSON.stringify(this?.panel?.options?.style || {}));
      style["justify-content"] = {
        left: "flex-start",
        right: "flex-end",
        center: "center"
      }[style["text-align"] || "center"];
      // convert to flex style
      style["align-items"] = {
        top: "flex-start",
        bottom: "flex-end",
        center: "center",
        middle: "center"
      }[style["vertical-align"] || "top"];
      if (!style["justify-content"]) {
        style["justify-content"] = "flex-start"; // force left alignment
        style["text-align"] = "justify"; // add text-align
      }
      style["-webkit-justify-content"] = style["justify-content"];
      style["-webkit-align-items"] = style["align-items"];
      return style;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    media() {
      if (this.$store.getters["dashboard/mode"] == "editor") return "screen";
      return this?.$route?.query?.media || this?.template?.media || "screen";
    },
    editableContent() {
      return this.isEditing && !isSyncEnabled(this.template, this.panelName);
    },
    panelName() {
      return this?.panel?.name || "unknown";
    },
    template() {
      return this.$store.getters["dashboard/editorTemplate"] || null;
    }
  },
  watch: {
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "RichTextForm") {
            // this.$emit("initCustomProperties", {
            //   panelName: this.panel.name,
            //   propertyEditor: RichTextForm
            // });
            // this.$nextTick(() => {
            //   this.setSideBar();
            // });
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    onEdit() {
      if (this.mode != "editor") return;
      this.$emit("initCustomProperties", {
        panelName: this.panel.name,
        propertyEditor: RichTextForm
      });
      this.setSideBar();
    },
    trigger(ev) {
      this.setSideBar();
      this.$nextTick(() => {
        this.$root.$emit("richtext:event", ev);
      });
    },
    setSideBar() {
      if (this.sidebar.name != "RichTextForm") {
        this.$root.$emit("controlSidebar:setContent", RichTextForm);
      }
    }
  },
  mounted() {
    let self = this;
    if (this.media == "print") {
      this.$nextTick(() => {
        this.$el.style["height"] = this.$el.style["min-height"];
      });
    }
    if (this.isEditing) {
      this.setSideBar();
    }
    $(this.$refs.edit).on("focus", function(e) {
      e.stopPropagation();
      self.hasFocus = true;
      const $this = $(this);
      e.target.focus();
      if (
        typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined"
      ) {
        var range = document.createRange();
        range.selectNodeContents(e.target);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(e.target);
        textRange.collapse(false);
        textRange.select();
      }
      $this.data("before", this.innerHTML);
    });

    $(this.$refs.edit).on("blur", function(e) {
      e.stopPropagation = true;
      e.preventDefault = true;
      const $this = $(this);
      if ($this.data("before") !== this.innerHTML) {
        $this.data("before", this.innerHTML);
        self.content = this.innerHTML;
      }
    });

    $(this.$refs.edit).on("keydown", function(e) {
      e.stopPropagation();
      if (e.which == 13) {
        if (e.ctrlKey) {
          e.preventDefault();
          e.target.blur();
          self.hasFocus = false;
        }
      }
    });
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.editor-toolbar {
  position: absolute;
  right: 0px;
  width: 100%;
  border-top: 3px solid #3c8dbc;
}

.editing {
  border-top-color: #f39c12;
}

.editor-toolbar > div {
  background-color: white;
}

.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}

.editable {
  white-space: pre-wrap;
  caret-color: rgb(250, 42, 5);
}

.editable:focus {
  border: 0;
  outline: 0;
}

br:before {
  content: "\A";
  white-space: pre-line;
}

.panel-canvas {
  display: flex;
  position: absolute;
  height: calc(100%);
  width: calc(100%);
}
</style>

